import React from 'react'
import Layout from '../components/Layout'
import CallBackBanner from '../components/CallBackBanner'
import PromotionBanner from '../components/PromotionBanner/PromotionBanner'
import GeneralEnquiryForm from '../components/shared/Form/GeneralEnquiryForm'
import img from '../images/industry-sector/team_industry.svg'
import IndustryTemplateLayout from '../components/IndustrySector/IndustryTemplateLayout'
import MainGrid from '../components/styling/MainGrid'
import Breadcrumb from '../components/shared/Breadcrumb/Breadcrumb'
import IndustryLeftCol from '../components/IndustrySector/IndustryLeftCol'
import IndustryRightCol from '../components/IndustrySector/IndustryRightCol'
import IndustryDescription from '../components/IndustrySector/IndustryDescription'
import logo from '../images/homepage/popin-anim/retailer.svg'
import CaseStudyContent from '../components/CaseStudy/CaseStudyContent'

const breadcrumbData = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'About us',
    url: '/about-us',
  },
  {
    title: 'Case Studies',
    url: '/case-studies',
  },
  {
    title: 'Tom Gamble Sponsorship',
    url: '/tom-gamble-sponsorship',
  },
]

const TomGamble = () => {
  return (
    <Layout
      activeLink="/case-studies"
      title="R&D Tom Gamble Sponsorship"
      description="R&D Tom Gamble Sponsorship"
    >
      <MainGrid noPaddingMobile noPaddingTablet>
        <Breadcrumb data={breadcrumbData} />
      </MainGrid>
      <IndustryTemplateLayout>
        <IndustryLeftCol
          category="Case Studies"
          title="Tom Gamble Sponsorship"
          backLink="/case-studies"
        />
        <IndustryRightCol>
          <IndustryDescription title="Tom Gamble Sponsorship" />
          <CaseStudyContent
            heading=""
            paragraph='"I have known Kevin at RDA for over 10 years now. Only in the last 18 months have we managed to align the stars
            and make a sponsorship opportunity happen. I have to say that
            if Carlsberg did sponsors, RDA would be it! Easy to
            deal with, committed, passionate and supportive of our Racing
            Driver Tom Gamble - they have exceeded all expectations.
            Having committed to support Tom in 2020 pre Covid, Kevin stuck
            to his word even through this global pandemic. RDA
            supported Tom in the 2020 European Le Mans series which
            resulted in Tom taking championship victory. This would not
            have been possible without the support and backing of R&D
            Advisors. Kevin and his team have so many synergies and
            similarities to the world of motorsport which make this
            partnership work perfectly."'
            // imgDesktop={img}
            // imgMobile={img}
            alt="Aeroplane"
            logo={logo}
            content={[
              <div>
                <h6>Eddie Reynolds – Tom Gamble Sponsorship</h6>
              </div>,
            ]}
          />
        </IndustryRightCol>
      </IndustryTemplateLayout>
      <PromotionBanner />
      <GeneralEnquiryForm />
      <CallBackBanner />
    </Layout>
  )
}

export default TomGamble
